import * as React from 'react';
import { WidgetHeader } from 'app/components/WidgetHeader/WidgetHeader';
import {
  IRegion,
  ISupportedRoutes,
  IOnRoadTasksModel,
  getTotalVehicleTypesCount,
  IVehicleTypeModel,
} from 'app/models';
import { RouteComponentProps } from 'react-router';
import { OnRoadTasksList } from 'app/components/OnRoadTasksList/OnRoadTasksList';
import { Grid, Button, CircularProgress } from '@material-ui/core';

import gradients from 'app/helpers/colors';
import { DashboardWidget } from 'app/components/DashboardWidget/DashboardWidget';

import CommuteOutlinedIcon from '@material-ui/icons/CommuteOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import CallToActionOutlinedIcon from '@material-ui/icons/CallToActionOutlined';
import {
  ServeedoSelectButton,
  ISelectButtonStyle,
  ISelectButtonOption,
} from 'app/components/common';

import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';

export interface IProps extends RouteComponentProps<any> {
  selectedRegion: IRegion | null;
  getOnRoadTask: () => void;
  onRoadTasks: IOnRoadTasksModel;
  fetchFleetStatus: () => void;
  vehicleTypes: IVehicleTypeModel[];
  fetchVehicleTypes: () => void;
  requestUpdateTasks: () => void;
}
export interface IState {
  selectedVehicleTypeIndex: number;
}
class OnRoadTasks extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectedVehicleTypeIndex: 0,
    };
  }

  componentDidMount() {
    const { selectedRegion, fetchVehicleTypes } = this.props;
    fetchVehicleTypes();
    if (selectedRegion && selectedRegion.key !== 'all') {
      this.props.getOnRoadTask();
      this.props.fetchFleetStatus();
    }
  }

  componentDidUpdate(prevProps: IProps) {
    const { selectedRegion } = this.props;
    if (prevProps.selectedRegion !== selectedRegion && selectedRegion) {
      if (selectedRegion.key === 'all') {
        this.props.history.push(ISupportedRoutes.DASHBOARD);
      } else {
        const newUrl = '/' + selectedRegion.key + ISupportedRoutes.TASKS;
        this.props.history.push(newUrl);
        this.props.getOnRoadTask();
        this.props.fetchFleetStatus();
      }
    }
  }

  renderRightPanel = (): JSX.Element => {
    const {
      vehicleTypes,
      onRoadTasks: { isUpdatingTasks },
      requestUpdateTasks,
    } = this.props;
    const { selectedVehicleTypeIndex } = this.state;
    const options: ISelectButtonOption[] = [
      {
        label: 'All',
        value: 'all',
      },
    ];
    vehicleTypes.forEach((type) => {
      options.push({
        label: type.name,
        value: type.id,
      });
    });

    return (
      <div className="l-flex">
        <Button
          variant="contained"
          color="primary"
          size="medium"
          disabled={isUpdatingTasks}
          startIcon={
            isUpdatingTasks ? (
              <CircularProgress disableShrink size={18} thickness={4} />
            ) : (
              <RefreshOutlinedIcon />
            )
          }
          onClick={requestUpdateTasks}
        >
          {isUpdatingTasks ? 'UPDATING...' : 'UPDATE TASKS'}
        </Button>{' '}
        <ServeedoSelectButton
          options={options}
          onSelect={(option) => {
            const selectedVehicleTypeIndex = options.indexOf(option);
            this.setState({ selectedVehicleTypeIndex });
          }}
          selectedOption={options[selectedVehicleTypeIndex]}
          buttonStyle={ISelectButtonStyle.DEFAULT}
          leftIcon={<CommuteOutlinedIcon />}
        />
      </div>
    );
  };

  render(): JSX.Element {
    const {
      selectedRegion,
      onRoadTasks: { onRoadTasksList, isLoading, onRoadTaskStatus },
      vehicleTypes,
    } = this.props;
    const { selectedVehicleTypeIndex } = this.state;
    const selectedVehicleId =
      selectedVehicleTypeIndex === 0 ? null : vehicleTypes[selectedVehicleTypeIndex - 1].id;
    return (
      <div className={'container'}>
        <WidgetHeader
          headerText={'Onroad Tasks'}
          headerSmallText={selectedRegion ? selectedRegion.name.toUpperCase() : 'All'}
          rightPanel={this.renderRightPanel()}
        />
        <Grid container spacing={3} style={{ marginTop: 24, marginBottom: 12 }}>
          <Grid item lg={4} sm={6} xs={12}>
            <DashboardWidget
              title="Deployable vehicles"
              value={getTotalVehicleTypesCount(
                onRoadTaskStatus['deployableVehicles'].filter((status) =>
                  selectedVehicleTypeIndex ? status.vehicleTypeId === selectedVehicleId : true,
                ),
              )}
              icon={CommuteOutlinedIcon}
              image={gradients.green}
            />
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <DashboardWidget
              title="available hotspot places"
              value={getTotalVehicleTypesCount(
                onRoadTaskStatus['availablePlaces'].filter((status) =>
                  selectedVehicleTypeIndex ? status.vehicleTypeId === selectedVehicleId : true,
                ),
              )}
              icon={LocationOnOutlinedIcon}
              image={gradients.orange}
            />
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            <DashboardWidget
              title="Batteries to replace"
              value={getTotalVehicleTypesCount(
                onRoadTaskStatus['batteriesToReplace'].filter((status) =>
                  selectedVehicleTypeIndex ? status.vehicleTypeId === selectedVehicleId : true,
                ),
              )}
              icon={CallToActionOutlinedIcon}
              image={gradients.blue}
            />
          </Grid>
        </Grid>
        <OnRoadTasksList
          onRoadTasksListList={onRoadTasksList.filter(
            (task) =>
              (task.vehicleTypeId && task.vehicleTypeId === selectedVehicleId) ||
              selectedVehicleTypeIndex === 0,
          )}
          isLoading={isLoading}
        />
      </div>
    );
  }
}

export default OnRoadTasks;
