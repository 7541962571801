import { handleActions, Reducer } from 'redux-actions';
import { IRootState } from 'app/reducers/AppState';
import { IHotspotsActionsType } from 'app/actions';
import { IHotspotsModel, IHotspotModel, IOnRoadTaskModel } from 'app/models';

const initialState: IHotspotsModel = {
  hotspotsList: [],
  selectedHotspot: null,
  isLoading: false,
  tasks: [],
  isDropTasksLoading: false,
  isSaving: false,
};

// tslint:disable-next-line:typedef
export const hotspotsReducer: Reducer<IRootState.HotspotsState, IHotspotsModel> = handleActions<
  IRootState.HotspotsState,
  IHotspotsModel | IHotspotModel | IHotspotModel[] | IOnRoadTaskModel[]
>(
  {
    [IHotspotsActionsType.REQUEST_HOT_SPOTS]: (state = initialState): IRootState.HotspotsState => ({
      ...state,
      isLoading: true,
      selectedHotspot: null,
      hotspotsList: [],
    }),
    [IHotspotsActionsType.RECEIVE_HOT_SPOTS]: (
      state = initialState,
      action,
    ): IRootState.HotspotsState => ({
      ...state,
      isLoading: false,
      hotspotsList: action.payload as IHotspotModel[],
    }),
    [IHotspotsActionsType.FAILURE_HOT_SPOTS]: (state = initialState): IRootState.HotspotsState => ({
      ...state,
      isLoading: false,
    }),

    [IHotspotsActionsType.REQUEST_ADD_OR_UPDATE_HOTSPOT]: (
      state = initialState,
    ): IRootState.HotspotsState => ({
      ...state,
      isSaving: true,
    }),
    [IHotspotsActionsType.RECEIVE_ADD_OR_UPDATE_HOTSPOT]: (
      state = initialState,
      action,
    ): IRootState.HotspotsState => ({
      ...state,
      isSaving: false,
      selectedHotspot: action.payload as IHotspotModel,
    }),
    [IHotspotsActionsType.FAILURE_ADD_OR_UPDATE_HOTSPOT]: (
      state = initialState,
    ): IRootState.HotspotsState => ({
      ...state,
      isSaving: false,
    }),

    [IHotspotsActionsType.REQUEST_HOT_SPOT_DETAILS]: (
      state = initialState,
    ): IRootState.HotspotsState => ({
      ...state,
      isLoading: true,
    }),
    [IHotspotsActionsType.HANDLE_VIEW_HOT_SPOTS]: (
      state = initialState,
      action,
    ): IRootState.HotspotsState => ({
      ...state,
      isLoading: false,
      selectedHotspot: action.payload as IHotspotModel,
    }),
    [IHotspotsActionsType.FAILURE_HOT_SPOT_DETAILS]: (
      state = initialState,
    ): IRootState.HotspotsState => ({
      ...state,
      isLoading: false,
    }),

    [IHotspotsActionsType.REQUEST_DROP_TASKS]: (
      state = initialState,
    ): IRootState.HotspotsState => ({
      ...state,
      tasks: [],
      isDropTasksLoading: true,
    }),
    [IHotspotsActionsType.RECEIVE_DROP_TASKS]: (
      state = initialState,
      action,
    ): IRootState.HotspotsState => ({
      ...state,
      tasks: action.payload as IOnRoadTaskModel[],
      isDropTasksLoading: false,
    }),
    [IHotspotsActionsType.FAILURE_DROP_TASKS]: (
      state = initialState,
    ): IRootState.HotspotsState => ({
      ...state,
      isDropTasksLoading: false,
    }),
  },
  initialState,
);
