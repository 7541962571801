import * as React from 'react';

import {
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  Divider,
  Link,
} from '@material-ui/core';

import {
  ServeedoTableHeader,
  ITableHeaderCells,
  ServeedoTableToolbar,
  NoData,
  ServeedoTableLoading,
  ServeedoStatus,
  ServeedoPhotoViewer,
} from 'app/components/common';
import { IOnRoadTaskModel } from 'app/models';

import { getSorting, stableSort } from 'app/helpers/common';
import { makeDateRelative } from 'app/helpers/time-helper';

const headerCells: ITableHeaderCells[] = [
  { id: 'quantity', numeric: false, disablePadding: false, label: 'Quantity' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'type', numeric: false, disablePadding: false, label: 'Photo proof' },
  { id: 'employeeId', numeric: true, disablePadding: false, label: 'Employee' },
  { id: 'comment', numeric: false, disablePadding: false, label: 'Comment' },
  { id: 'updatedAt', numeric: false, disablePadding: false, label: 'Last Update' },
];

export interface IProps {
  tasks: IOnRoadTaskModel[];
  isLoading: boolean;
}

export const DropTaskList: React.SFC<IProps> = ({ isLoading, tasks }) => {
  const [order, setOrder] = React.useState<any>('desc');
  const [orderBy, setOrderBy] = React.useState('updatedAt');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [selected, setSelected] = React.useState([]);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [photoUrl, setPhotoUrl] = React.useState<string>('');

  const handleRequestSort = (event: any, property: string) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds: any = [].map((n: any) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  let list = stableSort<IOnRoadTaskModel>(tasks, getSorting(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return (
    <Paper>
      <ServeedoTableToolbar title={'Drop tasks'} />
      <div className="table-wrapper">
        <Table aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
          <ServeedoTableHeader
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={[].length}
            headerCells={headerCells}
          />
          <TableBody>
            {isLoading ? (
              <ServeedoTableLoading numberOfColumns={6} />
            ) : (
              list.map((task: IOnRoadTaskModel, index: number) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index}
                    style={{
                      cursor: 'default',
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {task.quantity}
                    </TableCell>
                    <TableCell>
                      <ServeedoStatus type={task.status} />
                    </TableCell>
                    <TableCell>
                      {task.proofPhotoUrl && (
                        <Link
                          component="button"
                          variant="body1"
                          onClick={() => {
                            setOpenModal(true);
                            setPhotoUrl(task.proofPhotoUrl!);
                          }}
                        >
                          View
                        </Link>
                      )}
                    </TableCell>
                    <TableCell>
                      {task.employee ? task.employee.fullname : task.employeeId}
                    </TableCell>
                    <TableCell>{task.comment}</TableCell>
                    <TableCell>{makeDateRelative(task.updatedAt)}</TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </div>
      {!isLoading && !list.length && (
        <>
          <NoData />
          <Divider />
        </>
      )}
      <TablePagination
        rowsPerPageOptions={[50, 100, 250]}
        component="div"
        count={list.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'previous page',
        }}
        nextIconButtonProps={{
          'aria-label': 'next page',
        }}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <ServeedoPhotoViewer
        modalOpen={openModal}
        photoUrl={photoUrl}
        closeModal={() => {
          setOpenModal(false);
          setPhotoUrl('');
        }}
      />
    </Paper>
  );
};
